import * as React from "react"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import {Link as OrginLink} from "gatsby"
import moment from "moment"

import AnchorLink  from "/src/components/link-anchor"

import iconBlank from "/src/images/icon-blank-02.png"
import iconPdf from "/src/images/icon-pdf.png"
import iconExcel from "/src/images/icon-excel.png"
import iconWord from "/src/images/icon-word.png"
import iconPpt from "/src/images/icon-ppt.png"

function TopicsList(props) {
  
  const {t} = useTranslation();

  const type = props.node.frontmatter.post ? props.node.frontmatter.post  : 'topics'

  var termDay = ''
  if(type==='topics'){
    let now = new Date()
    now = new Date(now.getFullYear(),now.getMonth(),now.getDate())
    const postdate = new Date(moment(props.node.frontmatter.postdate).format(`YYYY/MM/DD`))
    termDay = (now - postdate) / 86400000
    termDay =   termDay >= 0 && termDay <= 14 ? <span className="new">NEW</span>:''  
  }

  const title = props.lang==='ja' ? props.node.frontmatter.title : props.node.frontmatter.titleen
  const date = props.lang==='ja' 
              ? <span className="date">{props.node.frontmatter.date}{termDay}</span> 
              : <span className="date">{props.node.frontmatter.dateen}{termDay}</span>

  const external = props.lang==='ja' ? props.node.frontmatter?.external||'' : props.node.frontmatter?.externalen||''
  const externalext = props.node.frontmatter?.externalext||''
  const internal = props.node.frontmatter?.internal||''
  const ankchor = props.node.frontmatter?.anchor||''
  const publicURL = props.lang==='ja' ? props.node.frontmatter.attachments?.publicURL || '' : props.node.frontmatter.attachmentsen?.publicURL || ''
  const relativeDirectory = props.lang==='ja' ? props.node.frontmatter.attachments?.relativeDirectory || '' : props.node.frontmatter.attachmentsen?.relativeDirectory || ''
  const prettySize = props.lang==='ja' ? props.node.frontmatter.attachments?.prettySize.replace(/\s+/g, '') || '' : props.node.frontmatter.attachmentsen?.prettySize.replace(/\s+/g, '') || ''
  const base = props.lang==='ja' ? props.node.frontmatter.attachments?.base || '' : props.node.frontmatter.attachmentsen?.base || ''

  
  const href = relativeDirectory==='assets'?"/"+type+"/assets/"+base:publicURL
  const extension = props.lang==='ja' ? props.node.frontmatter.attachments?.extension || '' : props.node.frontmatter.attachmentsen?.extension || ''
  const nolink = props.node.frontmatter?.nolink || ''
  const titlelang = props.node.frontmatter?.titlelang || props.lang
  
  let link = ''
  let icon = '';

  if(nolink==="true"){
    link = <>{date}<span className="title" lang={titlelang} >{title}</span></>
  }else if(publicURL!==''){
    let sizetext = '';
    if(extension==='pdf'){
      icon = iconPdf
      sizetext = '(PDF '+prettySize+')';
    }else if(extension==='xlsx'){
      icon = iconExcel
      sizetext = '(Excel '+prettySize+')';
    }else if(extension==='pptx'){
      icon = iconPpt
      sizetext = '(PowerPoint '+prettySize+')';
    }else if(extension==='docx'){
      icon = iconWord
      sizetext = '(Word '+prettySize+')';
    }

    link = <OrginLink to={href}>
              {date}
              <span className="title" lang={titlelang}>
                {title}
                {sizetext}
              </span>
              <img className="icon" src={ icon } alt="" />
            </OrginLink>
  }else if(internal!==''){
    if(ankchor!==''){
      link = <AnchorLink href={internal+ankchor}>
                {date}
                <span className="title" lang={titlelang}>{title}</span>
              </AnchorLink>
    }else{
      link = <Link to={internal} >
              {date}
              <span className="title" lang={titlelang}>{title}</span>
            </Link>
    }
  }else if(external!==''){
    if(externalext==='pdf'){
      icon = iconPdf
    }else{
      icon = iconBlank
    }
    link = <a href={external} rel="noreferrer noopener" target="_blank">
            {date}
            <span className="title" lang={titlelang}>
              {title}
            </span>
            <img className="icon" src={ icon } alt={t("新規タブで開きます")} />
          </a>
  }else{
    link = <Link to={props.node.fields.slug} >{date}<span className="title" lang={titlelang}>{title}</span></Link>
  }
  

  if(title){
    return (
      <>
        <li>
          {link}
        </li>
      </>
    )
  }else{
    return (
      <></>
    )
  }
}

export default TopicsList
